import React from 'react'
import Footer from '../footer/Footer'
import AdminDatatable from './admindatatable/AdminDatatable'
import AdminInfo from './adminInfo/AdminInfo'
import "./adminpost.css"

function AdminRecentpost() {
    return (
        <div className='admin'>
    <div className='admin grid grid-cols-1 md:grid-cols-3  '>
            <div className='tablechat rounded-xl  col-span-2'>
                <AdminDatatable />
            </div>

            <div className='tablechat'>
                <AdminInfo />
            </div> 

    </div>
    </div>
  )
}

export default AdminRecentpost