import React from 'react'
import Chategory from '../../components/chategory/Chategory'

import Datatable2 from '../../components/datatable/Datatable2'
import Datatable4 from '../../components/datatable/Datatable4'
import Datatable5 from '../../components/datatable/Datatable5'
import Datatable6 from '../../components/datatable/Datatable6'
import Datatable7 from '../../components/datatable/Datatable7'
import Footer from '../../components/footer/Footer'
import Display from '../../components/form/Display'
import Form from '../../components/form/Form'

import Information2 from '../../components/information/Information2'
import Information4 from '../../components/information/Information4'
import Information5 from '../../components/information/Information5'
import Information6 from '../../components/information/Information6'
import Information7 from '../../components/information/Information7'
import Navbar from '../../components/navbar/Navbar'
import New from '../../components/new/New'
import Read, { RealtimeData } from '../../components/read/Read'
import Example from '../../components/test/test'
import Top from '../../components/top/Top'

function Semester7() {
  return (
      <div className='sem1'>
       
          <Top />
          <div className='second  rounded-lg flex gap-6'>
              <Information7 className="fixed "/>
              <Datatable7 />
          </div>

          

          

          <Footer />
      </div>
  )
}

export default Semester7