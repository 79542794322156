import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

function StartFirebase() {
    const firebaseConfig = {
  apiKey: "AIzaSyC5aRjUD0XkKZ49ksED9ddgkrltnWMTgJ0",
  authDomain: "fir-upl-downl.firebaseapp.com",
  databaseURL: "https://fir-upl-downl-default-rtdb.firebaseio.com",
  projectId: "fir-upl-downl",
  storageBucket: "fir-upl-downl.appspot.com",
  messagingSenderId: "601664462120",
  appId: "1:601664462120:web:5b94e3df14cf992870841d",
  measurementId: "G-2RYSFRDVNR"
    };
    const app = initializeApp(firebaseConfig);
    return getDatabase(app);
    
} 
  
export default StartFirebase;