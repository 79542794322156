
import "./adminDatatable.css"
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot,
  query,
    where,
    updateDoc
    
    
} from "firebase/firestore";


import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import { Link } from "react-router-dom";

import { useForkRef } from "@mui/material";
import { db } from "../../../firebase";

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const AdminDatatable8 = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        // const querySnapshot = await getDocs(collection(db, "data/recent/details"));

        // const snapShot = await querySnapshot.where("Boolean", "==", true).get();

        const q = query(collection(db, "data/sem8/details"), where("Boolean", "==", false));

        const querySnapshot = await getDocs(q);

    
       
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });

        setData(list);
        console.log(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();

  //   // LISTEN (REALTIME)
  //   const unsub = onSnapshot(
  //     collection(db, "users"),
  //     (snapShot) => {
  //       let list = [];
  //       snapShot.docs.forEach((doc) => {
  //         list.push({ id: doc.id, ...doc.data() });
  //       });
  //       setData(list);
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );

  //   return () => {
  //     unsub();
  //   };
  }, []);

  
  console.log(data);

    {/*   DELETE FUNCTIONALITY   */}
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "data/sem8/details", id));
        setData(data.filter((item) => item.id !== id));
        
        console.log(data);
    } catch (err) {
      console.log(err);
    }
    };
    

    {/*   UPDATE FUNCTIONALITY   */ }
    
     const handleUpdate = async (id) => {
    try {
        await updateDoc(doc(db, "data/sem8/details", id), {
          
            Boolean: true
      } );
        
        
        console.log(data);
    } catch (err) {
      console.log(err);
    }
    };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



 
 {/*  
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to="/users/test" style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  */}
  return (
      <div className="shadow-lg rounded-xl">
          
                    {/*  
                <div className="datatableTitle">
                    Add New User
                    <Link to="/users/new" className="link">
                    Add New
                    </Link>
                </div>
                <DataGrid
                    className="datagrid"
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                    checkboxSelection
                /> 
                LOGIC START

                <ul className=' '>
                {data.map((datas) => (
                            <li
                                key={datas.id}
                                className="relative shadow-lg  mb-4 rounded-md p-4 hover:bg-gray-100">

                                <h3 className="text-xl pt-4 leading-5 text-gray-600 font-bold">
                                {datas.firstname}
                                </h3>

                                <h3 className="text-sm pt-4 leading-5 text-gray-500">
                                <a href={datas.img} target="_blank" rel="noreferrer">
                                    <button>Click</button>
                                </a>
                                </h3>
                            </li>
                            
                ))}
                </ul> */}
                    
          <div className='chategory '>
                <h1 className="text-2xl md:text-4xl  font-semibold text-gray-100 rounded-lg heading p-4 mb-4" >SEMESTER 8</h1>
          
              
             
              
              <TableContainer component={Paper}>
       <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Subject</StyledTableCell>
            <StyledTableCell >Semester</StyledTableCell>
            <StyledTableCell >Description</StyledTableCell>
            <StyledTableCell>Student</StyledTableCell>
            <StyledTableCell>Branch</StyledTableCell>
            <StyledTableCell >View</StyledTableCell>
            <StyledTableCell >Approve</StyledTableCell>
            <StyledTableCell >Delete</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((datas) => (
            <StyledTableRow key={datas.semester} className="text-center">
              <StyledTableCell component="th" scope="row">
               {datas.lastname}
              </StyledTableCell>
              <StyledTableCell >{datas.semester }</StyledTableCell>
              <StyledTableCell >{datas.description}</StyledTableCell>
              <StyledTableCell >{datas.firstname}</StyledTableCell>
              <StyledTableCell >{datas.branch }</StyledTableCell>
                  <StyledTableCell   className="">
                     <a href={datas.img} target="_blank" rel="noreferrer">
                          <button className=' rounded-md bg-blue-400 text-teal-50 w-full p-2'>View</button>
                      </a>
                  </StyledTableCell>
                  <StyledTableCell  > <CheckCircleIcon className="cursor-pointer text-green-600 bg-green-200 p-1 rounded-lg" fontSize="large" onClick={() => handleUpdate(datas.id)}/> </StyledTableCell>
                <StyledTableCell  > <DeleteIcon className="cursor-pointer text-red-600 bg-red-200 p-1 rounded-lg" fontSize="large"  onClick={() => handleDelete(datas.id)}/> </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        </Table>
    </TableContainer>
                  
                
              

               

                    
              
          </div>

    </div>
  );
};


export default AdminDatatable8