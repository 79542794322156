import "./login.css"
import { auth } from "../../firebase"
import {  signInWithEmailAndPassword } from "firebase/auth";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { TabbTitle } from "../../components/Genfunc";


function Login() {

    TabbTitle('LOGIN')

    const [error, setError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const nevigate = useNavigate()

    const {dispatch} = useContext(AuthContext)


    const handleLogin = (e) => {
        e.preventDefault();
    
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;

            dispatch({type:"LOGIN", payload:user })

           nevigate("/dashboard")
        })
        .catch((error) => {
            setError(true);
            // ..
        });

    }


    return (
      
        <div className="login mx-auto">
      <div className=' grid grid-cols-1 md:grid-cols-2  first shadow-lg gap-4 rounded-xl '>

          
                <div className="rounded-lg flex justify-center">
                    <img src="https://img.freepik.com/free-photo/3d-social-media-platform-online-social-communication-applications-concept-emoji-webpage-search-icons-chat-chart-with-smartphone-background-3d-illustration_73903-1023.jpg?w=996" width={"800px"} className="rounded-xl"/>
                </div>
          
                <div className="flex justify-center pb-6 pt-4">
                        <form onSubmit={handleLogin} className=" px-6">
                            <h1 className="text-center font-semibold text-4xl text-gray-600">Hello Again!</h1>
                            <p className="text-center pt-4 text-sm text-gray-500 pb-6">Welcome back you've been missed!</p>
                    <input type="email" placeholder="     Enter username" onChange={e=>setEmail(e.target.value)} required className="rounded-lg w-full"/>
                    <input type="password" placeholder='      Password' onChange={e=>setPassword(e.target.value)} required className="rounded-lg w-full"/>
                    <button type='submit' className="button ml-3 rounded-lg mt-6 ">Sign In</button>
                    {error && <span> Wrong email or password! </span>}
                            </form>
                </div>


          
            </div>
            
            </div>
  )
}

export default Login