import "./footer.css";
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';


function Footer() {
  return (
      <div className='footer grid grid-cols-3 text-center pt-4'>
          <div>
              <h1 className="text-xl font-semibold">ABOUT US</h1>
              <p  className="pt-4 px-4 text-xs">
                QP Space – We provide previous year papers and help in 
                understanding the question pattern and reduces the amount of efforts of students 
                help them prepare well.
              </p>
          </div>

          <div>
              <h1 className="text-xl font-semibold">CONTACT US</h1>
              <p className="pt-4 text-xs">
                <div className="flex justify-evenly text-xs">
                        <span className=' '><LocationOnIcon /></span> Guwahati, India    
                  

                  
                        <span className=' '><PhoneIcon /></span> +91 4568952349  
                  

                   
                        <span className=' '><EmailIcon /></span> qpspace@gmail.com
                  </div>
              </p>
          </div>

          <div>
              <h1 className="text-xl font-semibold">FOLLOW US</h1>
              <p className="pt-4 flex justify-evenly text-xs">
                  <TwitterIcon className="cursor-pointer" />
                  <LinkedInIcon className="cursor-pointer"  />
                  <FacebookIcon className="cursor-pointer"  />
              </p>
          </div>
      </div>
  )
}

export default Footer