import React from 'react'
import Navbar from '../navbar/Navbar'
import { Link } from "react-router-dom";


function Ask() {
  return (
      <div>
          <Navbar />
          
          <div className="information shadow-lg text-lg ">
      <h1 className="text-2xl font-bold text-gray-600" >CATEGORY</h1>
      <div className="categories">
        <ul className="flex flex-col  md:flex-col md:space-x-8 md:mt-0 md:text-lg md:font-medium ">
          <li>
            <a href="#" className="block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0  md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"></a>
         </li>
          
          <li>
             <Link to="rec"  className="block py-2 pr-4 pl-3 text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 ">RECENT </Link>
          </li>

          <li>
             <Link to="sem1"  className="block py-2 pr-4 pl-3 text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 ">SEMESTER &nbsp; 01</Link>
          </li>
          <li>
             <Link to="sem2"  className="block py-2 pr-4 pl-3 text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 ">SEMESTER &nbsp; 02</Link>
          </li>
          <li>
             <Link to="sem3"  className="block py-2 pr-4 pl-3 text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 ">SEMESTER &nbsp; 03</Link>
          </li>
          <li>
             <Link to="sem4" className="block py-2 pr-4 pl-3 text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 ">SEMESTER &nbsp; 04</Link>
          </li>
          <li>
             <Link to="sem5"  className="block py-2 pr-4 pl-3 text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 ">SEMESTER &nbsp; 05</Link>
          </li>
          <li>
             <Link to="sem6"  className="block py-2 pr-4 pl-3 text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 ">SEMESTER &nbsp; 06</Link>
          </li>
          <li>
             <Link to="sem7"  className="block py-2 pr-4 pl-3 text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 ">SEMESTER &nbsp; 07</Link>
          </li>
          <li>
             <Link to="sem8"  className="block py-2 pr-4 pl-3 text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 ">SEMESTER &nbsp; 08</Link>
          </li>
          
     
       </ul>
      </div>
    </div>
      </div>
  )
}

export default Ask