import React from 'react'
import Chategory from '../../components/chategory/Chategory'

import Datatable2 from '../../components/datatable/Datatable2'
import Datatable4 from '../../components/datatable/Datatable4'
import Footer from '../../components/footer/Footer'
import Display from '../../components/form/Display'
import Form from '../../components/form/Form'

import Information2 from '../../components/information/Information2'
import Information4 from '../../components/information/Information4'
import Navbar from '../../components/navbar/Navbar'
import New from '../../components/new/New'
import Read, { RealtimeData } from '../../components/read/Read'
import Example from '../../components/test/test'
import Top from '../../components/top/Top'

function Semester4() {
  return (
      <div className='sem1'>
        
          <Top />
          <div className='second  rounded-lg flex gap-6'>
              <Information4 className="fixed "/>
              <Datatable4 />
          </div>

          

          

          <Footer />
      </div>
  )
}

export default Semester4