import React from 'react'
import "./contact.css"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { TabbTitle } from '../../components/Genfunc';


function Contact() {

    TabbTitle('CONTACT US')
  return (
      <div className='contact'>

          <div className='grid grid-cols-1 md:grid-cols-2 shadow-xl mt-20 p-6 m-6 rounded-lg bg-white md:mx-20 mb-60 lg:mx-40'>
          
              {/*LEFT */}
              <div className=' justify-center items-center md:pt-20 md:px-10'>
                  <h1 className='left text-4xl font-semibold '>Get In Touch</h1>
                  <p className='pt-4 text-gray-600'>We are here for you! How can we help?</p>

                  <form className='pt-6'>
                    <input type="name" placeholder="     Enter your name" required className="rounded-lg w-full " />
                    <input type="email" placeholder="     Enter your email "  required className="rounded-lg w-full "/>
                    <textarea placeholder="     Enter your feedback " required className="rounded-lg w-full p-1 pb-6 m-2 " />
                    
                       <button type='submit' className="button1 p-3 text-gray-100 ml-3 rounded-lg mt-6 text-xl">Submit</button>
                  </form>
              </div>

              {/*LEFT */}
              <div className='pb-6'>
                  <img src="https://cdn.dribbble.com/users/542205/screenshots/5380805/media/71dcfb8fa5ef0c6f5459aa77f100fb7a.png?compress=1&resize=400x300&vertical=top" width={"700px"} className="rounded-xl " />
                  
                  <div >
                        <span className='icon1 pl-10 '><LocationOnIcon /></span> Guwahati, India    
                  </div>

                   <div className='mt-4'>
                        <span className='icon1 pl-10 '><PhoneIcon /></span> +91 4568952349  
                  </div>

                   <div className='mt-4'>
                        <span className='icon1 pl-10 '><EmailIcon /></span> qpspace@gmail.com
                  </div>
    
                  
                  
              </div>
          
          </div>
      
      </div>
  )
}

export default Contact