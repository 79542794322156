import React from 'react'
import { db } from "./firebase1"
import { uid } from "uid"
import {useState, useEffect} from "react"


function Display() {

    const [data, setData] = useState({});

    useEffect(() => {
        db.child("userData").on("value", (Snapshot) => {
            if (Snapshot.val() !== null) {
                setData({ ...Snapshot.val() });
            } 
            
            else {
                setData({});
            }
        });

        return () => {
            setData({})
        };
    }, []);

  return (
      <div>
          <tbody>
              {Object.keys(data).map((id, index) => {
                  return (
                      <tr key={id}>
                          <td>{data[id].firseName}</td>
                          <td>{data[id].lastName}</td>
                          <td>{data[id].registration}</td>
                          <td>{data[id].branch}</td>
                          <td>{data[id].semester}</td>
                          <td>{data[id].description}</td>
                          <td>{data[id].ingUrl}</td>
                          <td>{data[id].email}</td>
                      </tr>
                  );
            })}
          </tbody>
          
      </div>
  )
}

export default Display