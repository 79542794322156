import Chategory from '../../components/chategory/Chategory'
import Datatable from '../../components/datatable/Datatable'
import Footer from '../../components/footer/Footer'
import Display from '../../components/form/Display'
import Form from '../../components/form/Form'
import { TabbTitle } from '../../components/Genfunc'
import Information from '../../components/information/Information'
import Navbar from '../../components/navbar/Navbar'
import New from '../../components/new/New'
import Read, { RealtimeData } from '../../components/read/Read'
import Example from '../../components/test/test'
import Top from '../../components/top/Top'

import './home.css'

function Home() {

  TabbTitle('HOME');

  return (
    <div className='home'>
      
      {/*   <Navbar /> */}
          
          <Top />
          <div className='second  rounded-lg flex gap-6'>
              <Information className="fixed "/>
              <Datatable />
          </div>

       {/*   
      
          <Example />
          
          
        <Chategory />
     T */}
      

          <Footer />
      </div>
  )
}

export default Home