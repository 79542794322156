import React from 'react'
import AdminDatatable1 from '../admindatatable/AdminDatatable1'
import AdminDatatable2 from '../admindatatable/AdminDatatable2'
import AdminDatatable8 from '../admindatatable/AdminDatatable8'
import Admininfo1 from '../adminInfo/AdminInfo1'
import Admininfo2 from '../adminInfo/Admininfo2'
import Admininfo8 from '../adminInfo/Admininfo8'



function AdminPost2() {
    return (
    <div className='admin grid grid-cols-1 md:grid-cols-3 '>
            <div className='tablechat rounded-xl  col-span-2'>
                <AdminDatatable2 />
                
            </div>

            <div className='tablechat'>
                <Admininfo2 />
            </div> 
    </div>
  )
}

export default AdminPost2