import React from 'react'
import Chategory from '../../components/chategory/Chategory'
import Datatable from '../../components/datatable/Datatable'
import Datatable1 from '../../components/datatable/Datatable1'
import Footer from '../../components/footer/Footer'
import Display from '../../components/form/Display'
import Form from '../../components/form/Form'
import Information from '../../components/information/Information'
import Information1 from '../../components/information/Information1'
import Navbar from '../../components/navbar/Navbar'
import New from '../../components/new/New'
import Read, { RealtimeData } from '../../components/read/Read'
import Example from '../../components/test/test'
import Top from '../../components/top/Top'
import './semester.css'

function Semester1() {
  return (
    <div className='sem1'>
      
      <Top />
      
         <div className='second  rounded-lg flex gap-6'>
              <Information1 className="fixed "/>
              <Datatable1 />
      </div>
      
      

      

          <Footer />
      </div>
  )
}

export default Semester1