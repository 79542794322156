import Footer from "../../components/footer/Footer"
import { TabbTitle } from "../../components/Genfunc"
import "./about.css"

function About() {

  TabbTitle('ABOUT US')
  return (
    <div className=' '>
      
      <div className="about grid grid-cols-1 md:grid-cols-2 shadow-xl p-6 md:pb-20 m-6 rounded-lg md:mt-40  md:mx-20 md:mb-60 lg:mx-40">
        
        {/* LEFT */}
        <div className=" xl:mx-40 xl:mt-10">
          <h1 className="text-4xl md:text-6xl font-semibold text-gray-600">ABOUT <span className="head">US</span></h1>
          <p className="mt-10 text-gray-500">Exams are a very important part of a student’s life. To ace these exams,it is 
                quite necessary to have a proper preparation. For that going through the 
                previous year question papers are a must. We provide previous year papers and help in 
                understanding the question pattern and reduces the amount of efforts of students 
                help them prepare well.</p>
          
          <button type='submit' className="button1 p-2 xl:mt-40 text-gray-100 ml-3 rounded-lg mt-10 text-xl">Learn More</button>
        </div>

        {/* RIGHT */}
        <div className="mt-6">
          <img src="https://img.freepik.com/free-vector/happy-united-business-team_74855-6520.jpg?w=2000" width={"700px"} className="rounded-xl " />
        </div>

      </div>

      <Footer />
      
      </div>
  )
}

export default About