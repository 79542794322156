import { getAuth, signOut } from 'firebase/auth'
import React from 'react'
import Top from '../../components/top/Top'
import { auth } from "../../firebase"
import { AuthContext } from "../../context/AuthContext";
import { useContext, useState } from "react";
import Welcome from '../../components/welcome/Welcome';
import AdminRecentpost from '../../components/admin/AdminRecentpost';
import Featured from '../../components/admin/feature/Featured';
import AdminPost1 from '../../components/admin/feature/AdminPost1';
import AdminPost2 from '../../components/admin/feature/AdminPost2';
import AdminPost3 from '../../components/admin/feature/AdminPost3';
import { TabbTitle } from '../../components/Genfunc';

function Dashboard3() {


TabbTitle('DASHBOARD - SEMESTER 3')
  
  
  return (
    <div>
      <Welcome />

      <Featured />
          <AdminPost3 />
          
      
     
      </div>
  )
}

export default Dashboard3

