
import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  { name: "January", total: 1200 },
  { name: "Feburary", total: 2100 },
  { name: "March", total: 800 },
  { name: "April", total: 1600 },
  { name: "May", total: 900 },
  { name: "June", total: 1700 },
];


const Chart = ({aspect, title}) => {
  return (
    <div className="chart shadow-xl p-4 rounded-lg bg-white">
      <div className="text-xl pb-4 font-semibold text-gray-600">Last 6 Months <span className='head'>(active users)</span> </div> 
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart width={730} height={250} data={data}
  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
  <defs>
    <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
    
  </defs>
  <XAxis dataKey="name" stroke="gray"/>
  <YAxis />
  <CartesianGrid strokeDasharray="3 3" />
  <Tooltip />
  <Area type="monotone" dataKey="total" stroke="#8884d8" fillOpacity={1} fill="url(#total)" />

</AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Chart