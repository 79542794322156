
import "./App.css";
import Home from "./pages/home/Home";
import Upload from "./pages/upload/Upload";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import New from "./components/new/New";
import Ask from "./components/upload/Ask";


import Semester1 from "./pages/semesters/Semester1";
import Semester2 from "./pages/semesters/Semester2";
import New1 from "./components/new/New1";
import New2 from "./components/new/New2";
import New3 from "./components/new/New3";
import New4 from "./components/new/New4";
import New5 from "./components/new/New5";
import New6 from "./components/new/New6";
import New7 from "./components/new/New7";
import New8 from "./components/new/New8";
import Semester3 from "./pages/semesters/Semester3";
import Semester4 from "./pages/semesters/Semester4";
import Semester5 from "./pages/semesters/Semester5";
import Semester6 from "./pages/semesters/Semester6";
import Semester7 from "./pages/semesters/Semester7";
import Semester8 from "./pages/semesters/Semester8";
import Sidebar from "./components/sidebar/sidebar";
import About from "./pages/about/About";
import Dashboard from "./pages/dashboard/Dashboard";
import SidebarDashboard from "./components/sidebar/SidebarDashboard";
import Login from "./pages/login/Login";
import { AuthContext } from "./context/AuthContext";
import { useContext } from "react";
import Dashboard1 from "./pages/dashboard/Dashboard1";
import Dashboard2 from "./pages/dashboard/Dashboard2";
import Dashboard3 from "./pages/dashboard/Dashboard3";
import Dashboard4 from "./pages/dashboard/Dashboard4";
import Dashboard5 from "./pages/dashboard/Dashboard5";
import Dashboard6 from "./pages/dashboard/Dashboard6";
import Dashboard7 from "./pages/dashboard/Dashboard7";
import Dashboard8 from "./pages/dashboard/Dashboard8";
import Contact from "./pages/contact/Contact";




function App() {

  const { currentUser } = useContext(AuthContext)
  
  

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  }


  return (
    <div className="App">
      
      <BrowserRouter>
        
        <Sidebar>

          <Routes>
                <Route path="/" element={<Home />}></Route>

                {/*   UPLOAD PAGE START */}
                <Route path="qp" element={<Ask />} />
                  
                  <Route path="qp/rec" element={<New />}></Route>
                  <Route path="qp/sem1" element={<New1 />}></Route>
                  <Route path="qp/sem2" element={<New2/>}></Route>
                  <Route path="qp/sem3" element={<New3 />}></Route>
                  <Route path="qp/sem4" element={<New4 />}></Route>
                  <Route path="qp/sem5" element={<New5 />}></Route>
                  <Route path="qp/sem6" element={<New6 />}></Route>
                  <Route path="qp/sem7" element={<New7 />}></Route>
                <Route path="qp/sem8" element={<New8 />}></Route>
                
                {/*   UPLOAD PAGE START */}
                

                <Route path="semester1" element={<Semester1 />}></Route>
                <Route path="semester2" element={<Semester2 />}></Route>
                <Route path="semester3" element={<Semester3 />}></Route>
                <Route path="semester4" element={<Semester4 />}></Route>
                <Route path="semester5" element={<Semester5 />}></Route>
                <Route path="semester6" element={<Semester6 />}></Route>
                <Route path="semester7" element={<Semester7 />}></Route>
                <Route path="semester8" element={<Semester8 />}></Route>

            <Route path="dashboard" element={
              <RequireAuth>
              <Dashboard />
              </RequireAuth>}>
            </Route>

            <Route path="dashboard1" element={
              <RequireAuth>
               <Dashboard1 />
              </RequireAuth>}>
            </Route>

            <Route path="dashboard2" element={
              <RequireAuth>
               <Dashboard2 />
              </RequireAuth>}>
            </Route>

            <Route path="dashboard3" element={
              <RequireAuth>
               <Dashboard3 />
              </RequireAuth>}>
            </Route>

            <Route path="dashboard4" element={
              <RequireAuth>
               <Dashboard4 />
              </RequireAuth>}>
            </Route>

            <Route path="dashboard5" element={
              <RequireAuth>
               <Dashboard5 />
              </RequireAuth>}>
            </Route>

            <Route path="dashboard6" element={
              <RequireAuth>
               <Dashboard6 />
              </RequireAuth>}>
            </Route>


            <Route path="dashboard7" element={
              <RequireAuth>
               <Dashboard7 />
              </RequireAuth>}>
            </Route>

            <Route path="dashboard8" element={
              <RequireAuth>
               <Dashboard8 />
              </RequireAuth>}>
            </Route>

            
            <Route path="about" element={<About />}></Route>
            <Route path="contact" element={<Contact />}></Route>
            <Route path="login" element={<Login />}></Route>

            
                
                
          </Routes>
          
        </Sidebar>

       

      </BrowserRouter>
    </div>
  );
}

export default App;
