import "./top.css"
import SearchIcon from '@mui/icons-material/Search';

function Top() {
  return (
      <div className='top rounded-2xl shadow-xl'>
          <div className='grid grid-cols-2  top_cont'>
              <div className="flex justify-center pl-8 text-left flex-col ">
                  <h1 className="text-xl   md:text-6xl  pt-2 font-bold pb-4 text-zinc-100">Question PaperSpace</h1>
                  
                  <div className="md:pt-20 pt-4">
                      <input type="email" placeholder=" Search... " required className="rounded-lg w-1/2 " /><SearchIcon fontSize="large" className="bg-white rounded-lg cursor-pointer"  />
                    </div>   
              </div>

              <div className=" mt-6">
              <img src="https://firebasestorage.googleapis.com/v0/b/fir-upl-downl.appspot.com/o/sem%2Fbg4.png?alt=media&token=f6f9a352-2868-48e4-a899-72fa07ddc981" width={"600px"} />
              </div>
          </div>
      </div>
  )
}

export default Top