import React from 'react'
import { AuthContext } from '../../context/AuthContext';
import { auth } from '../../firebase';
import "./welcome.css"
import { getAuth, signOut } from 'firebase/auth'
import { useContext, useState } from "react";
import LogoutIcon from '@mui/icons-material/Logout';

function Welcome() {

    const {dispatch} = useContext(AuthContext)

  const handleLogout = (e) => {
        e.preventDefault();

    signOut(auth).then(() => {
    
      const user = null;
      dispatch({ type: "LOGOUT", payload:user })
      
    
  }).catch((error) => {
    console.log(error);
  })

    }
    
  return (
      <div className='welcome bg-slate-50 pt-2 md:p-4'>
          <div className=' flex  justify-between p-4  md:px-20'>
              
              <div className='flex  items-center justify-center'>
                <h1 className='text-lg md:text-4xl text-gray-600 font-bold'>Welcome back, </h1> <span className='text-sm md:text-2xl pt-2 pl-2 font-semibold head'>Admin</span>
              </div>

              <div className=''>
                <button onClick={handleLogout} className="flex flex-row rounded-lg shadow-xl w-full p-1 bg-orange-500 text-gray-100 px-4 text-lg">Logout  <LogoutIcon className='ml-2' /></button>
              </div>
          
          
          </div>
    </div>
  )
}

export default Welcome