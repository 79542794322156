import { Link, NavLink } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ContactsIcon from '@mui/icons-material/Contacts';
import GroupsIcon from '@mui/icons-material/Groups';
import LoginIcon from '@mui/icons-material/Login';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import DashboardIcon from '@mui/icons-material/Dashboard';
import SidebarMenu from "./SidebarMenu";
const routes = [

   {
    path: "/dashboard",
    name: "Dashboard",
    icon: <DashboardIcon fontSize="large"/>,
  },
  {
    path: "/qp",
    name: "Upload Page",
    icon: <UploadFileIcon fontSize="large"/>,
    exact: true,
    subRoutes: [
      {
        path: "/qp/rec",
        name: "Recent ",
        // icon: <AccessTimeIcon />,
      },
      {
        path: "/qp/sem1",
        name: "Semester 1",
        // icon: <FaLock />,
      },
      {
        path: "/qp/sem2",
        name: "Semester 2",
        // icon: <FaMoneyBill />,
        },
      {
        path: "/qp/sem3",
        name: "Semester 3",
        // icon: <FaMoneyBill />,
        },
      {
        path: "/qp/sem4",
        name: "Semester 4",
        // icon: <FaMoneyBill />,
        },
      {
        path: "/qp/sem5",
        name: "Semester 5",
        // icon: <FaMoneyBill />,
        },
      {
        path: "/qp/sem6",
        name: "Semester 6",
        // icon: <FaMoneyBill />,
        },
      {
        path: "/qp/sem7",
        name: "Semester 7",
        // icon: <FaMoneyBill />,
        },
      {
        path: "/qp/sem8",
        name: "Semester 8",
        // icon: <FaMoneyBill />,
      },
    ],
  },
  {
    path: "/saved",
    name: "Login",
    icon: <LoginIcon fontSize="large"/>,
  },
];

const SidebarDashboard = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };
  
  

    
//     const sidebar = {
//     open: (height = 1000) => ({
//     clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
//     transition: {
//       type: "spring",
//       stiffness: 20,
//       restDelta: 2
//     }
//         }),
        
//      closed: {
//     clipPath: "circle(30px at 40px 40px)",
//     transition: {
//       delay: 0.5,
//       type: "spring",
//       stiffness: 400,
//       damping: 40
//     }
//   }
// };
    
    const open = "100px";
    

  return (
    <div>
      <div className="main-container ">
        <motion.div
          animate={{
            width: isOpen ? "280px"  : "70px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section flex justify-between  mb-20">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                 <Link to="/"  className="flex items-center" style={{textDecoration:"none"}}>QP Space</Link> 
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
           
             <MenuIcon fontSize="large" onClick={toggle} />
            </div>
          </div>
          {/* 
          <div className="search ">
            <div className="search_icon">
              <SearchIcon fontSize="large" />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  placeholder="Search"
                />
              )}
            </AnimatePresence>
          </div>
          */}
          <section className="routes ">
            {routes.map((route, index) => {
                

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link "
                  activeClassName="active "
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text "
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </motion.div>

        <main >{children}</main>
      </div>
    </div>
  );
};

export default  SidebarDashboard;
