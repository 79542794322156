import React from 'react'
import { Link } from "react-router-dom";

function Information2() {
  return (
    <div className="information shadow-lg text-lg ">
      <h1 className="text-2xl md:text-4xl font-semibold text-gray-100 rounded-lg heading p-4 mb-4" >CATEGORY</h1>
      <div className="categories">
        <ul className="flex flex-col mt-4 md:flex-col md:space-x-8 md:mt-0  md:text-lg md:font-medium">
          <li>
            <a href="#" className="block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0   md:hover:text-blue-700 md:p-0  dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"></a>
              </li>
              
         <li>
             <Link to="/"  className="block py-2 pr-4 pl-3 links text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 hover:text-gray-500  hover:scale-105 transition duration-200 ease-out ">RECENT </Link>
          </li>

          <li>
              <Link to="/semester1"   className="block py-2 pr-4 pl-3 links text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0  hover:text-gray-500  hover:scale-105 transition duration-200 ease-out ">SEMESTER &nbsp; 01</Link>
          </li>
          <li>
             <Link to="/semester2"  className="block py-2 pr-4 pl-3 link_un text-gray-500 text-xl font-bold   border-b border-gray-100 bg-gray-100  md:border-0  ">SEMESTER &nbsp; 02</Link>
          </li>
          <li>
             <Link to="/semester3" className="block py-2 pr-4 pl-3 links text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 hover:text-gray-500  hover:scale-105 transition duration-200 ease-out  ">SEMESTER &nbsp; 03</Link>
          </li>
          <li>
             <Link to="/semester4" className="block py-2 pr-4 pl-3 links text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 hover:text-gray-500  hover:scale-105 transition duration-200 ease-out  ">SEMESTER &nbsp; 04</Link>
          </li>
          <li>
             <Link to="/semester5" className="block py-2 pr-4 pl-3 links text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 hover:text-gray-500  hover:scale-105 transition duration-200 ease-out  ">SEMESTER &nbsp; 05</Link>
          </li>
          <li>
             <Link to="/semester6" className="block py-2 pr-4 pl-3 links text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0  hover:text-gray-500  hover:scale-105 transition duration-200 ease-out ">SEMESTER &nbsp; 06</Link>
          </li>
          <li>
             <Link to="/semester7" className="block py-2 pr-4 pl-3 links text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 hover:text-gray-500  hover:scale-105 transition duration-200 ease-out  ">SEMESTER &nbsp; 07</Link>
          </li>
          <li>
             <Link to="/semester8" className="block py-2 pr-4 pl-3 links text-gray-400 border-b border-gray-100 hover:bg-gray-100  md:border-0 hover:text-gray-500  hover:scale-105 transition duration-200 ease-out  ">SEMESTER &nbsp; 08</Link>
          </li>
          
     
       </ul>
      </div>
    </div>
  )
}

export default Information2