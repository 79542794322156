import React from 'react'
import AdminDatatable1 from '../admindatatable/AdminDatatable1'
import AdminDatatable2 from '../admindatatable/AdminDatatable2'
import AdminDatatable3 from '../admindatatable/AdminDatatable3'
import AdminDatatable4 from '../admindatatable/AdminDatatable4'
import AdminDatatable5 from '../admindatatable/AdminDatatable5'
import AdminDatatable6 from '../admindatatable/AdminDatatable6'
import AdminDatatable7 from '../admindatatable/AdminDatatable7'
import AdminDatatable8 from '../admindatatable/AdminDatatable8'
import Admininfo1 from '../adminInfo/AdminInfo1'
import Admininfo2 from '../adminInfo/Admininfo2'
import Admininfo3 from '../adminInfo/Admininfo3'
import Admininfo4 from '../adminInfo/Admininfo4'
import Admininfo5 from '../adminInfo/Admininfo5'
import Admininfo6 from '../adminInfo/Admininfo6'
import Admininfo7 from '../adminInfo/Admininfo7'
import Admininfo8 from '../adminInfo/Admininfo8'



function AdminPost8() {
    return (
    <div className='admin grid grid-cols-1 md:grid-cols-3 '>
            <div className='tablechat rounded-xl  col-span-2'>
                <AdminDatatable8 />
                
            </div>

            <div className='tablechat'>
                <Admininfo8 />
            </div> 
    </div>
  )
}

export default AdminPost8