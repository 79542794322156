import React from 'react'

import "./new.css"
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { useState,useEffect } from "react";
import { addDoc, collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { async } from "@firebase/util";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Navbar from "../navbar/Navbar";
import Footer from '../footer/Footer';
import { TabbTitle } from '../Genfunc';


function New8({ inputs, title }) {

  TabbTitle('UPLOAD - SEMESTER 8')

  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      console.log(name);
      const storageRef = ref(storage, `sem/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgresspercent(progress);
          console.log(" Sem2 Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  console.log(data);

   {/*   UPLOAD END */} 


   {/*   FORM START */} 
  const handelInputs = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };
  console.log(data);

  const handelAdd = async(e) => {
    e.preventDefault()

    try {
      const res = await addDoc(collection(db, "data/sem8/details"), {
        ...data,
        timeStamp: serverTimestamp(),
        Boolean: false,
      });
    
    }
    catch (err) {
      console.log(err)
    }
    alert("Data stored");
  };

   {/*   FORM END */} 


  return (
    <div> 
      <div className="">
          
    
      <div className="newContainer">
        
        <div className="to">
          <h1>{title}</h1>
        </div>

        <div className="bottom">
          <div className="left">
             {/*   
            <img src={file ? URL.createObjectURL(file) : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPAQa2mqTssNBB4MCR0WzuJxtZVQqAOGKJQHxSNcDVPs_frLrNvQVF0gWxbDCXwHrYJHo&usqp=CAU"} />
            <div className='innerbar' >Sem2 Upload is {progresspercent} % done</div>
             */} 
          </div>

          <div className="right">
            <form onSubmit={handelAdd}>
              
              {/* 
             <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="first-name" className="block  font-bold  text-3xl text-gray-300">
                            First name
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="given-name"
                            className="mt-1 p-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm  input rounded-lg"
                            
                            placeholder="Eg: Manashjyoti"
                                value={data.name}
                                   onChange={ handelInputs}                         
                              />
                            
                </div>
                
                          */}
                          
                               {/*   FORM START */} 

      <div className="data">
        
                  <h1 className="text-5xl font-bold text-gray-100 p-4 text-center " >UPLOAD QUESTION PAPERS</h1>
                   <h5 className="text-2xl font-bold text-gray-300  text-center ">Semester 8</h5>
          
          <div className="mt-5 md:mt-0 md:col-span-2  shadow-lg">
          
            <div className="shadow  sm:overflow-hidden">
              
              {/* FORM INPUTS START*/}
              <div className="px-4 py-5  space-y-6 sm:p-6">
                
                <div className="grid grid-cols-6 gap-6">
                      {/* INPUT START*/}
                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="first-name" className="block  font-bold  text-3xl text-gray-300">
                            Student Name
                          </label>
                          <input
                            type="text"
                            name="firseName"
                            id="firstname"
                            autoComplete="given-name"
                            className="mt-1 p-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm  input rounded-lg"
                            
                            placeholder="Eg: QP Space"
                                value={data.firstname}
                                   onChange={ handelInputs}                             
                            />
                        </div>
                  
                      {/* INPUT END*/}

                      {/* INPUT START*/}
                          
                            <div className="col-span-6 sm:col-span-3">
                              <label htmlFor="last-name" className="block  font-bold  text-3xl text-gray-300">
                                Subject Name
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                id="lastname"
                                autoComplete="given-name"
                                className="mt-1 p-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 input rounded-lg"
                                
                                placeholder="Eg: Course Subject"
                                value={data.lastname}
                                   onChange={ handelInputs}                                 
                                /> 
                            </div>
                          
                      {/* INPUT END*/}
                </div>


                <div className="grid grid-cols-6 gap-6">
                      {/* INPUT START*/}
                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="email" className="block  font-bold  text-3xl text-gray-300">
                            Email Address
                          </label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            autoComplete="given-name"
                            className="mt-1 p-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 input rounded-lg"
                            
                            placeholder="Eg: abc123@gmail.com"
                                value={data.email}
                                   onChange={ handelInputs}                             
                            />
                        </div>
                  
                      {/* INPUT END*/}

                      {/* INPUT START*/}
                          
                            <div className="col-span-6 sm:col-span-3">
                              <label htmlFor="last-name" className="block  font-bold  text-3xl text-gray-300">
                                Registration Number
                              </label>
                              <input
                                type="text"
                                name="registration"
                                id="registration"
                                autoComplete="given-name"
                                className="mt-1 p-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 input rounded-lg"
                                
                                placeholder="Eg: 190104848"
                                value={data.registration}
                                   onChange={ handelInputs}                                 
                                />
                            </div>
                          
                  {/* INPUT END*/}
                  
                  {/* INPUT START*/}
                  <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="country" className="block  font-bold  text-3xl text-gray-300">
                        Branch
                      </label>
                      <input
                                type="text"
                                name="branch"
                                id="branch"
                                autoComplete="given-name"
                                className="mt-1 p-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 input rounded-lg"
                                
                                placeholder="Eg: CSE, IT, ECE, BET"
                                value={data.branch}
                                   onChange={ handelInputs}                                 
                                />
                  </div>
                  
                  {/* INPUT END*/}

                  {/* INPUT START*/}
                   <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="country" className="block  font-bold  text-3xl text-gray-300">
                        Semester
                      </label>
                      <input
                                type="text"
                                name="semester"
                                id="semester"
                                autoComplete="given-name"
                                className="mt-1 p-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 input rounded-lg"
                                
                                placeholder="Eg: Semester 8"
                               value={data.semester}
                                   onChange={ handelInputs}                                 
                                />
                  </div>
                  
                  {/* INPUT END*/}
                  </div>

                  <div>
                    <label htmlFor="about" className="block  font-bold  text-3xl text-gray-300">
                      Description
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="description"
                        name="description"
                        rows={3}
                        className="input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md p-4"
                        
                        placeholder="Eg: Brief description for the uploaded question paper."
                                value={data.description}
                                   onChange={ handelInputs}                         
                        defaultValue={''}
                      />
                    </div>
                    
                  </div>

                  

                  <div>
                    <label className="block text-sm font-medium text-gray-700"> </label>
                    <div className=" input mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file"
                            className="relative cursor-pointer  rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <span>Upload a file</span>
                            <input
                                    type="file"
                                    id="file"
                                    style={{ display: "none" }}
                                    onChange={(e)=> setFile(e.target.files[0])}
                                  />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                            <p className="text-xs text-gray-500">PNG, JPG, PDF up to 2MB</p>
                            <p className="text-xs text-gray-500">Upload is {progresspercent} % done</p>
                      </div>
                    </div>
                  </div>
              </div>
              {/* FORM INPUTS END*/}
                <div className='text-center pb-4'>
                              <button disabled={per !== null && per < 100} type="submit" className=" inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >  Send</button>
                        </div>
              </div>
           
          </div>
         
      </div>

      {/* FIRST END */} 
              

             
              
              
            </form>
          </div>
        </div>
      </div>
      </div>
      <Footer />
      </div>
  )
}

export default New8