import "./featured.css"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Chart from "../chart/Chart";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';


function Featured() {
    return (
      
        <div className='main  grid grid-cols-1 md:grid-cols-2 items-center p-6 gap-6'>
            
            {/* FIRST */}
            <div className="featured shadow-xl rounded-lg p-8">
                    <div className="flex justify-between p-2 pb-6 items-center">
                        <h1 className="title2 "><SignalCellularAltIcon fontSize="large" /> Analytics</h1>
                        <MoreVertIcon className="icon cursor-pointer" />
                    </div>
                    <div className="bottom">
                        <div className="featureschart " >
                        <CircularProgressbar value={70} text={"70%"} strokeWidth={5 } />
                        </div>
                        <p className="title1 ">Daily active users</p>
                        <p className="amount font-bold">150</p>
                        <p className="desc">Previous active users</p>

                        <div className="summary">
                        <div className="item">
                            <div className="itemTitle">Target</div>
                            <div className="itemResult positive">
                            <KeyboardArrowUpIcon fontSize="small" />
                            <div className="resultAmount">20K</div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="itemTitle">Last Week</div>
                            <div className="itemResult negative">
                            <KeyboardArrowDownIcon fontSize="small" />
                            <div className="resultAmount">15K</div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="itemTitle">Last Month</div>
                            <div className="itemResult positive">
                            <KeyboardArrowUpIcon fontSize="small" />
                            <div className="resultAmount">25K</div>
                            </div>
                        </div>
                        </div>
                    </div>
            </div>

            {/* SECOND */}

            <div>
                <Chart />
            </div>
    </div>
  )
}

export default Featured