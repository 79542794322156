import StartFirebase from "./fire";
import React from "react";
import { ref, onValue } from "firebase/database";

const db = StartFirebase();

export class RealtimeData extends React.Component{
  constructor() {
    super();
    this.state = {
      tableData: []
    }
  }

  componentDidMount() {
    const dbRef = ref(db, 'userData');

    onValue(dbRef, (snapshot) => {
      let records = [];

      snapshot.forEach(childSnapshot => {
        let keyName = childSnapshot.key;
        let data = childSnapshot.val();
        records.push({"key": keyName, "data": data})
      });
      this.setState({ tableData: records });
    });
  }

  render() {
    return (
      <tbody>
        {this.body.tableData.map((rowdata) => {
          <h1>{rowdata.lastName}</h1>

        })}
      </tbody>
    )
  }

}