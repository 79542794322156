

import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot,
   query,
  where,
} from "firebase/firestore";

import { db } from "../../firebase";
import { Link } from "react-router-dom";
import './datatable.css'


const Datatable1 = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        // const querySnapshot = await getDocs(collection(db, "data/sem1/details"));

        
        const q = query(collection(db, "data/sem1/details"), where("Boolean", "==", true));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
        console.log(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();

  //   // LISTEN (REALTIME)
  //   const unsub = onSnapshot(
  //     collection(db, "users"),
  //     (snapShot) => {
  //       let list = [];
  //       snapShot.docs.forEach((doc) => {
  //         list.push({ id: doc.id, ...doc.data() });
  //       });
  //       setData(list);
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );

  //   return () => {
  //     unsub();
  //   };
  }, []);

  

  const handleDelete =  (id) => {
    setData(data.filter((item) => item.id !== id));
  };
  console.log(data)
 {/*  
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to="/users/test" style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  */}
  return (
      <div className="">
          
                    {/*  
                <div className="datatableTitle">
                    Add New User
                    <Link to="/users/new" className="link">
                    Add New
                    </Link>
                </div>
                <DataGrid
                    className="datagrid"
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                    checkboxSelection
                /> 
                LOGIC START

                <ul className=' '>
                {data.map((datas) => (
                            <li
                                key={datas.id}
                                className="relative shadow-lg  mb-4 rounded-md p-4 hover:bg-gray-100">

                                <h3 className="text-xl pt-4 leading-5 text-gray-600 font-bold">
                                {datas.firstname}
                                </h3>

                                <h3 className="text-sm pt-4 leading-5 text-gray-500">
                                <a href={datas.img} target="_blank" rel="noreferrer">
                                    <button>Click</button>
                                </a>
                                </h3>
                            </li>
                            
                ))}
                </ul> */}
                    
          <div className='chategory '>
                <h1 className="text-2xl md:text-4xl font-semibold text-gray-100 rounded-lg heading p-4 mb-4" >SEMESTER 1</h1>
          
              
              {data.map((datas) => (

               <div className="categories shadow-md p-4 rounded-xl mt-4 hover:bg-gray-100 hover:scale-105 transition duration-200 ease-out">
                  <sapn className="bg-red-300 p-2 rounded-lg text-sm"> SEM {datas.semester}</sapn> <sapn className="bg-red-300 p-2 rounded-lg text-sm"> Branch {datas.branch}</sapn>
                      <h1 className='pt-4 text-xl font-bold text-gray-600'>{datas.lastname }</h1>
                      <p className='text-sm pt-2 text-gray-500'>{datas.description }</p>
                    <a href={datas.img} target="_blank" rel="noreferrer">
                          <button className='mt-4 p-2 rounded-md bg-blue-400 text-teal-50'>DOWNLOAD</button>
                    </a>
                </div>

                 ))}
          </div>

    </div>
  );
};


export default Datatable1